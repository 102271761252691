import React, { useContext } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import MainContext from '../../Configs/Context/MainContext'
import { FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../Configs/Routes';

const ConnectAccount = () => {
  const context = useContext(MainContext);
  const navigate = useNavigate();
  const activePlan = context?.profile?.currentActivePlan;

  return (

    <div className='accountConnect my-5 mx-auto p-4'>
      <h4 className='mb-4 fw-bold'>Connect Account</h4>
      <p className='mb-3'>You haven't connected an exchange to FOREX</p>

      <div className="planBtns my-3">
        <div className='d-flex flex-column align-items-center' style={{ minWidth: '260px' }}>
          {context?.loading?.userPlan ? (
            <Button className='infoBtn'>Getting Your Plan Details ... <Spinner size="sm" style={{ borderWidth: '2px' }} /></Button>
          ) : (
            <>
              <Button className={(!context?.buySubscription && activePlan?.validity >= 12) && 'infoBt'} disabled={(!context?.buySubscription && activePlan?.validity >= 12) || context?.loading?.userPlan} onClick={() => { context?.setPurchaseOpen(true) }}>
                {(context?.buySubscription || activePlan?.validity < 12) ? "Purchase Plan" : `Current Plan : $ ${activePlan?.price} (${activePlan?.validity} ${activePlan?.validityType})`}
                {(!context?.buySubscription && activePlan?.validity >= 12) && <FaCheckCircle style={{ color: "#fff", marginLeft: '5px' }} />}
              </Button>
              {(!context?.buySubscription && activePlan?.validity < 12) &&
                <>
                  <p style={{ textAlign: 'start', fontSize: '12px', color: 'red' }}><span>Current Plan:</span> $ {activePlan?.price} ({activePlan?.validity} {activePlan?.validityType})</p>
                  <p style={{ textAlign: 'start', fontSize: '12px' }}>Minimum Plan Required: 12 MONTH</p>
                </>
              }
              {context?.buySubscription &&
                <>
                  <p style={{ textAlign: 'start', fontSize: '12px', color: 'red' }}>No Active Plan</p>
                  <p style={{ textAlign: 'start', fontSize: '12px' }}>Minimum Plan Required: 12 MONTH</p>
                </>
              }
            </>
          )}
        </div>
        {/* {!context?.buySubscription && ( */}
        {/* <div style={{ minWidth: '260px' }}>
          {console.log('loader', context?.loading)}
          {context?.loading?.fuelFee ? (
            <>
              <Button className='infoBtn'>Getting your fuel fee ... <Spinner size="sm" style={{ borderWidth: '2px' }} /></Button>
            </>
          ) : (
            <>
              <Button className={context?.assetCount.fuelAccountBalance?.total > 20 && 'infoBtn'} disabled={context?.assetCount.fuelAccountBalance?.total > 20 || context?.loading.fuelFee} onClick={() => navigate(ROUTES.WALLET, { state: { tabName: 'deposit' } })}>
                {context?.assetCount.fuelAccountBalance?.total > 20 ?
                  <>Fuel Fee : {context?.assetCount?.fuelAccountBalance ? context?.assetCount.fuelAccountBalance?.total?.toFixed(4) : "00"}</>
                  : "Add Fuel Fee"}
              </Button>
              {context?.assetCount.fuelAccountBalance?.total < 20 && (
                <>
                  <p style={{ textAlign: 'start', fontSize: '12px', color: 'red' }}>Insufficient Fuel Fee : $ {context?.assetCount?.fuelAccountBalance ? context?.assetCount.fuelAccountBalance?.total?.toFixed(4) : "00"}</p>
                  <p style={{ textAlign: 'start', fontSize: '12px' }}>Minimum Fuel Fee Required : $20</p>
                </>
              )}
            </>
          )}
        </div> */}
        {/* )} */}
      </div>

      {context?.hasFuelFee && context?.level1Pass && !context?.level2Pass ? (
        // context?.userFxData?.status === "PENDING" ?
        <Button className='connectBtn' onClick={() => navigate(ROUTES.ACCOUNT)}>Connect Now</Button>
        // : ""
      ) : ""}
    </div >

  )
}

export default ConnectAccount