import React, { useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import './security.css';
import LoginPwdChange from './LoginPwdChange';
import TransactionPwdChange from './TransactionPwdChange';
import { IoIosLock } from 'react-icons/io';

const SecurityCenter = () => {
  return (
    <>
      <div className="d-flex flex-wrap align-items-center justify-content-between mb-3 gap-3">
        <h5>Security Center</h5>
      </div>

      <Row className='gy-4'>
        <Col md={12} lg={6} xxl={6} className='text-center'>
          <img src='/images/securityImg.png' alt='' className='img-fluid mx-auto' />
        </Col>
        <Col md={12} lg={6} xxl={6}>
          <Accordion className='securityAccordion' defaultActiveKey="">
            <Accordion.Item eventKey="0" className='mb-3'>
              <Accordion.Header>
                <div className='accordionIcon'><IoIosLock /></div>
                <div>
                  <h5>Login Password</h5>
                  <p>Used To Protect Account Security</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <LoginPwdChange />
              </Accordion.Body>
            </Accordion.Item>
            {/* <Accordion.Item eventKey="1">
              <Accordion.Header>
                <div className='accordionIcon'><IoIosLock /></div>
                <div>
                  <h5>Transaction Password</h5>
                  <p>Used To Protect Account Balance</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <TransactionPwdChange />
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </Col>
      </Row>
    </>
  )
}

export default SecurityCenter