import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import MainContext from '../../Configs/Context/MainContext';
import axios from 'axios';
import ApiConfig from '../../Configs/ApiConfig';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import ROUTES from '../../Configs/Routes';

const ForgetPassword = () => {
  const navigate = useNavigate();
  const context = useContext(MainContext);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [formData, setFormData] = useState({ email: "", });
  const [errors, setErrors] = useState({ email: "", });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (formData.email === "" || formData.email === undefined) {
      setErrors({ ...formData, email: 'Please enter Email Address.' });
      return;
    }

    try {
      setLoader(true);
      setErrors({ ...formData, email: '' });

      const res = await axios({
        method: "POST",
        url: ApiConfig.forgotPassword,
        params: {
          email: formData.email,
          // userId: values.user,
        },
      });

      if (res.data.statusCode === 200) {
        toast.success(res.data.message);
        window.localStorage.setItem("email", formData.email);
        setLoader(false);
        setIsSubmit(true);
        context?.setEndtime(moment().add(2, "m").unix());
        toast.success("OTP sent successfully to your registered email address.");
        navigate({
          pathname: ROUTES.FORGET_VERIFY,
          state: { email: formData.email, userI: formData.user },
        });
      } else {
        setLoader(false);
        toast.warn(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      if (error.response) {
        toast.error("User not found.");
        window.localStorage.setItem("email", formData.email);
      } else {
        toast.error("Something went wrong please try again");
      }
    }
  };

  return (
    <>
      <div className="authDiv">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              {/* <div className="bannerTxt my-lg-4 pe-lg-5 me-xxl-5">
                <h2>Forget Password!</h2>
                <p className="pe-xxl-5 me-xxl-5">A Workspace to Over 1 Million Happy User's Worldwide.</p>
              </div> */}
              <div className="authImg my-4">
                <img src='/images/user/authImg.png' alt="" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="loginDiv">
                <Form onSubmit={handleFormSubmit}>
                  <h2 className="authTitle mb-3">Forget Password</h2>
                  <div className='mb-3' style={{ fontSize: '12px' }}>
                    <p className='mb-1'>FOREX takes online safety seriously. To ensure the safety of your account, please follow the steps to verify and reset your password.</p>
                    <p style={{ color: '#42B57C' }}>After resetting the password, the function will be limited for less than 48 hours(Feature that include withdraw and transfer)</p>
                  </div>

                  <div className="mb-3">
                    {/* <label>Email Address <span style={{ color: "red" }}>*</span></label> */}
                    <Form.Control placeholder="Please Enter An Email Address" type="email" maxLength={256} value={formData.email} onChange={(e) => { setFormData({ ...formData, email: e.target.value }) }} />
                    <p style={{ fontSize: "12px", color: 'red', }}>{errors.email || ''}</p>
                  </div>

                  <Button type="submit" className="loginBtn mt-4" disabled={isLoading}>
                    Register {isLoading && <Spinner size="sm" />}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ForgetPassword