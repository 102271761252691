import React, { useContext, useEffect, useState } from 'react'
import Header from './Header'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Sidebar from './Sidebar'
import MainContext from '../Configs/Context/MainContext'
import ROUTES from '../Configs/Routes'

const Layout = () => {
  const context = useContext(MainContext);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const location = useLocation();
  const [sidebarToggle, setSidebarToggle] = useState(false)

  useEffect(() => {
    if (!token) {
      if (location.pathname === ROUTES.LOGIN || location.pathname === ROUTES.SIGNUP || location.pathname === ROUTES.SIGNUP_VERIFY || location.pathname === ROUTES.FORGET_PWD) {
      } else {
        navigate(ROUTES.LOGIN);
      }
    } else {
      if (location.pathname === ROUTES.LOGIN || location.pathname === ROUTES.SIGNUP || location.pathname === ROUTES.SIGNUP_VERIFY || location.pathname === ROUTES.FORGET_PWD) {
        navigate(ROUTES.DASHBOARD);
      }
    }
  }, [token, location])

  return (
    <>
      <div className='dashboardWrapper'>
        <div className={sidebarToggle ? 'sidebarWrapper sidebarToggle' : 'sidebarWrapper'}>
          <Sidebar setSidebarToggle={setSidebarToggle} />
        </div>
        <div className='rightSideWrapper'>
          <div className='headerWrapper'>
            <Header setSidebarToggle={setSidebarToggle} />
          </div>
          <div className='outletWrapper'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout