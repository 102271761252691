import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import ApiConfig from '../../Configs/ApiConfig';
import { Button, Spinner, Table } from 'react-bootstrap';

const ReferralView = ({ selectedLevel, setSelectedLevel, setShowDetails }) => {
  const token = localStorage.getItem("token");
  // const states = props.location.state;
  const [plansList, setPlansList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);

  const getPlanDetails = async () => {
    setLoading(true);
    const params = {
      limit: 10,
      page: page,
      level: selectedLevel,
      // userId: '6527e6cebad98dac79b1f99b',
    };
    const res = await Axios({
      method: "GET",
      url: ApiConfig.referralViewDetails,
      headers: { token: token },
      params: params,
    });
    if (res.data.statusCode === 200) {
      setPlansList(res.data.result);
      // setPagesCount(res.data.result[0]?.metadata[0]?.total_page);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      getPlanDetails();
    }
  }, [token, page, selectedLevel]);

  return (
    <div>
      <div className='d-flex flex-wrap align-items-center justify-content-between gap-1 mb-3'>
        <h5>Level - {selectedLevel}</h5>
        <Button className='backBtn' onClick={() => { setSelectedLevel(''); setShowDetails(false); }}>Back</Button>
      </div>

      <div>
        {loading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <>
            {plansList?.length !== 0 ? (
              <Table responsive className="forexTable">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Username (Referral Code)</th>
                    <th>Sponsor Name (Referral Code)</th>
                    <th>Paam Balance</th>
                    <th>status</th>
                    {/* <th>expiryDate</th> */}
                  </tr>
                </thead>

                <tbody>
                  {plansList &&
                    plansList.map((values, index) => {
                      return (
                        <tr key={`tableRow-${index}`}>
                          <td>{index + 1}</td>
                          <td>{values.username || '-'} ({values.referralCode})</td>
                          <td>{values.sponsorUserName || '-'} ({values.sponsorReferralCode})</td>
                          <td>{values?.paamBalance || '-'} </td>
                          <td>{values.status ? 'Active' : 'Inactive'}</td>
                          {/* <td>
                            {values.expiaryDate ? moment(values.expiaryDate).format("Do MMM YYYY") : '-'}
                          </td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            ) : (
              <div className='text-center'>
                <p>
                  No Data Found
                </p>
              </div>
            )}
          </>
        )}
      </div>

      {/* {!loading && plansList?.length !== 0 && (
        <div>
          <Pagination
            count={pagesCount}
            page={page}
            onChange={(e, v) => setPage(v)}
          />
        </div>
      )} */}
    </div>
  )
}

export default ReferralView