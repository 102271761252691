import React, { useState, useMemo } from "react";
import Axios from "axios";
import ApiConfig from "../../Configs/ApiConfig";
import { Col, Row, Spinner } from "react-bootstrap";

const PredictionProfit = () => {
  const token = localStorage.getItem("token");
  const [count, setCount] = useState([]);
  const [loading, setLoading] = useState(true);

  // get refferal community count  api integration
  const getnumbersofCommunityMember = async (values) => {
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.getActivationGainCount,
        headers: { token: token },
        data: { selectActivationType: "All" },
      });

      if (res.data.statusCode === 200) {
        setCount([
          {
            count:
              res.data.result.activationGain.todayProfit +
              res.data.result.activationTeamGain.todayProfit,
            count1:
              res.data.result.activationGain.totalProfit +
              res.data.result.activationTeamGain.totalProfit,
            name: "Today's Activation Reward",
            name1: "Total Activation Reward",
          },
          // {
          //   count: res.data.result.activationGain.todayProfit,
          //   count1: res.data.result.activationGain.totalProfit,
          //   name: "Today Direct Activation Reward",
          //   name1: "Total Direct Activation Reward",
          // },
          // {
          //   count: res.data.result.activationTeamGain.todayProfit,
          //   count1: res.data.result.activationTeamGain.totalProfit,
          //   name: "Today Team Activation Reward",
          //   name1: "Total Team Activation Reward",
          // },
          {
            count: res.data.result.aiStrategyProfit.todayProfit,
            name: "Today's Trading Reward",
            count1: res.data.result.aiStrategyProfit.totalProfit,
            name1: "Total Trading Reward",
          },
          // {
          //   count: res.data.result.aiStrategyDirectProfit.todayProfit,
          //   name: "Today Direct Strategy Reward",
          //   count1: res.data.result.aiStrategyDirectProfit.totalProfit,
          //   name1: "Total Direct Strategy Reward",
          // },
          // {
          //   count: res.data.result.aiStrategyIndirectProfit.todayProfit,
          //   name: "Today Team Strategy Reward",
          //   count1: res.data.result.aiStrategyIndirectProfit.totalProfit,
          //   name1: "Total Team Strategy Reward",
          // },
        ]);
        setLoading(false);
      } else {
        setLoading(false);
        // toast.error("res.data.responseMessage");
      }
    } catch (error) {
      setLoading(false);
      // toast.error(error.response.data.responseMessage);
    }
  };

  useMemo(() => {
    if (token) {
      getnumbersofCommunityMember();
    }
  }, [token]);

  return (
    <>
      <div>
        {loading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <Row className="g-4 mb-4">
            {count?.length > 0 && count.map((values) => {
              return (
                <>
                  <Col sm={6} md={4} xl={3}>
                    <div className="rewardDiv">
                      <p className="mb-3">{values.name}</p>
                      <h3>$ {values?.count ? values.count?.toFixed(4) : "0"}</h3>
                    </div>
                  </Col>
                  <Col sm={6} md={4} xl={3}>
                    <div className="rewardDiv">
                      <p className="mb-3">{values.name1}</p>
                      <h3>$ {values?.count1 ? values.count1?.toFixed(4) : "0"}</h3>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        )}
      </div>
    </>
  );
};

export default PredictionProfit;
