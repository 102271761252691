import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

const WithdrawTypeDropdown = ({ selected, setSelected }) => {


  return (
    <Dropdown className="withdraw-type-dropdown">
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {selected}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setSelected("system")}>
          System
        </Dropdown.Item>
        {/* <Dropdown.Item onClick={() => setSelected("MT4")}>MT4</Dropdown.Item>
        <Dropdown.Item onClick={() => setSelected("MT5")}>MT5</Dropdown.Item> */}
        <Dropdown.Item onClick={() => setSelected("paam")}>Paam</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default WithdrawTypeDropdown;
