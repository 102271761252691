import React from "react";

const AccountOptions = () => {
  return (
    <>
      <h3 className="text-center mb-4 mt-5">
        If you haven't set up a trading account yet, feel free to select from
        the options provided below.
      </h3>

      <div className="accountOptions">
        <span target="_blank" rel="noreferrer">
          <a href="/" target="_blank" rel="noreferrer">
            <img src="/images/logo.png" alt="" className="img-fluid" />
          </a>
          <a href="/" target="_blank" rel="noreferrer">
            <img src="/images/ivt-market.png" alt="" className="img-fluid" />
          </a>
        </span>
      </div>
    </>
  );
};

export default AccountOptions;
