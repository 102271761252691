import React, { useState, useMemo } from "react";
import moment from "moment";
import Axios from "axios";
// import { Pagination } from "@material-ui/lab";
import { Button, Spinner, Table } from "react-bootstrap";
import ApiConfig from "../../Configs/ApiConfig";
import Pagination from "../../Components/Pagination";

const ReferralSummary = () => {
  const token = localStorage.getItem("token");
  const [refferalDetails, setRefferalDetails] = useState([]);
  const [Active, setActive] = useState("Referral");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);


  const getReferralDetails = async (value) => {
    setLoading(true);
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.referralSummary,
        headers: { token: token },
        params: {
          // filter: value,
          page: currentPage,
          limit: 10,
          rewardType: Active,
        },
      });

      if (res.data.statusCode === 200) {
        setRefferalDetails(res.data.result.data);
        setTotalPage(res.data.result.totalPages);
        setLoading(false);
      } else {
        // toast.error("res.data.responseMessage");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // toast.error(error.response.data.responseMessage);
    }
  };
  useMemo(() => {
    if (token) {
      getReferralDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, currentPage, Active]);

  return (
    <>
      <div>
        <div className="walletBtns mb-3">
          <Button onClick={() => { setActive("Referral"); setCurrentPage(1); }} className={Active === "Referral" ? "active" : ""}>
            Activation reward
          </Button>
          <Button onClick={() => { setActive("Paam"); setCurrentPage(1); }} className={Active === "Paam" ? "active" : ""}>
            Trading Reward
          </Button>
        </div>
        <div>
          {loading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <>
              {refferalDetails?.length !== 0 ? (
                <>
                  <Table responsive className="forexTable">
                    <thead>
                      <tr>
                        <th>
                          {Active === "Referral" ? "Referral Bonus" : "Trading Bonus"}
                        </th>
                        {(Active === "ALL" ||
                          Active === "Prediction") && (
                            <th>
                              Pool ID
                            </th>
                          )}

                        {(Active === "ALL" || Active === "Referral") && (
                          <th>
                            Plan Active
                          </th>
                        )}
                        <th>
                          Referred Members
                        </th>
                        <th>
                          Reward Date and time
                        </th>
                        {/* {Active === "Referral" && (
                                <th>
                                  Plan Activation Date
                                </th>
                              )} */}
                        {(Active === "ALL" || Active === "Referral") && (
                          <th>
                            Plan Expiration Date
                          </th>
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {refferalDetails &&
                        refferalDetails?.map((values, index) => {
                          return (
                            <tr key={ index}>
                              <td >
                                $ {values.amount ? values.amount.toFixed(4) : "--"} ({values?.rewardType}) {values?.exchangeName || " "}
                              </td>
                              {(Active === "ALL" ||
                                Active === "Referral") && (
                                  <td>${values?.planDetails?.price} | {values?.planDetails?.type?.slice(0, 1)} | {values?.planDetails?.validity}
                                    {values?.planDetails?.validityType?.slice(0, 1)}
                                  </td>
                                )}
                              {(Active === "ALL" ||
                                Active === "Prediction") && (
                                  <td>
                                    {values?.receipt?.refId}
                                  </td>
                                )}
                              <td>
                                {values?.level ? values?.level : " -- "} | {values?.senderUserData?.nickName ? values.senderUserData?.nickName : " -- "} | {values?.senderUserData?.referralCode ? values?.senderUserData?.referralCode : " -- "}
                              </td>
                              <td>{moment(values?.createdAt).format("Do MMM YYYY, h:mm a")}</td>

                              {/* {Active === "Referral" && (
                                      <td>{moment(values?.planDetails?.startDate).format("Do MMM YYYY")}</td>
                                    )} */}
                              {(Active === "ALL" ||
                                Active === "Referral") && (
                                  <td>{moment(values?.planDetails?.expiaryDate).format("Do MMM YYYY, h:mm a")}</td>
                                )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>

                  {totalPage > 1 && !loading &&
                    <Pagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                  }
                </>
              ) : (
                <div className="text-center">
                  <p>No Data Found</p>
                </div>
              )}
            </>
          )}
        </div>
        {/* <div>
          {!loading && refferalDetails?.length !== 0 && (
            <div>
              <Pagination />
            </div>
          )}
        </div> */}
      </div>
    </>
  );
};

export default ReferralSummary;
