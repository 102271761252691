import React, { useContext, useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import ApiConfig from '../Configs/ApiConfig';
import Axios from 'axios';
import { toast } from 'react-toastify';
import MainContext from '../Configs/Context/MainContext';

const PurchasePlanModal = ({ handleClose }) => {
  const token = localStorage.getItem("token");
  const context = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [botDetails, SetBotDetails] = useState([]);

  const getSpotBotDetails = async (values) => {
    try {
      setDetailLoading(true);
      const res = await Axios({
        method: "POST",
        url: ApiConfig.getTradingBotDetails,
        headers: { token: token },
        data: { typeOfBot: values },
      });
      setDetailLoading(false);
      if (res.data.statusCode === 200) {
        if (res?.data?.result?.length > 0) {
          const plan = res.data.result.filter((item => item.validity >= 12));
          if (plan.length > 0) {
            SetBotDetails(plan[0]);
          }
        }
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      if (error.response.data.responseMessage) {
        toast.error(error.response.data.responseMessage);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  const confirmationHandler = async () => {
    if (confirm) {
      setLoading(true);
      try {
        const res = await Axios({
          method: "POST",
          url: ApiConfig.buyPlanData,
          headers: { token: token },
          params: { subscriptionPlanId: botDetails._id },
        });

        if (res.data.statusCode === 200) {
          // SetBotDetails(res.data.result);
          toast.success("Plan activated successfully");
          getPlanDetails();
          setLoading(false);
          handleClose();
          window.location.reload();
        } else {
          toast.error(res.data.responseMessage);
          setLoading(false);
          handleClose();
        }
      } catch (error) {
        if (error.response.data.responseMessage) {
          toast.error(error.response.data.responseMessage);
          setLoading(false);
          handleClose();
        } else {
          toast.error("Something went wrong!");
          setLoading(false);
          handleClose();
        }
      }
    }
  }

  const getPlanDetails = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.planDetails,
        headers: { token: token },
      });

      if (res.data.statusCode === 200) {
        // setAutoRenewal(res.data.result.renewalStatus == "ON" ? true : false);
        // setSubscribeType(res.data.result.type);
        if (res.data.result.status == true) {
          context?.setBuySubscription(false);
        } else {
          context?.setBuySubscription(true);
        }
      } else {
        toast.error(res.data.responseMessage);
      }
    } catch (error) {
      toast.error(error.response.data.responseMessage);
    }
  };

  useEffect(() => {
    if (token) {
      getSpotBotDetails("FutureBot");
    }
  }, [token])

  return (
    <>
      <Modal.Header closeButton>
        {/* <Modal.Title>Purchase Confirmation</Modal.Title> */}
      </Modal.Header>
      <div>
        <Modal.Body>
          <h3>Purchase Confirmation</h3>
          {detailLoading ?
            <Spinner className='my-3' />
            :
            <div className='purchasePlan mb-3'>
              <h2 className='mb-0 mt-3'>$ {botDetails?.price}</h2>
              <p>{botDetails?.validity} {botDetails?.validityType}</p>
            </div>
          }
          <Form.Check type='checkbox' checked={confirm} onChange={(e) => setConfirm(e.target.checked)} disabled={!botDetails._id}
            label={<>I acknowledge and agree to the terms of the FOREX Activation
              <a href={`#link`} target='_blank' rel="noreferrer" style={{ color: '#42B57C' }}> Service Agreement</a>.</>}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' type='submit' className='modalSubmit' onClick={confirmationHandler} disabled={loading || !confirm}>
            {loading ? <Spinner size="sm" /> : "Buy Now"}
          </Button>
        </Modal.Footer>
      </div>
    </>
  )
}

export default PurchasePlanModal