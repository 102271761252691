import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import ApiConfig from "../../Configs/ApiConfig";
import { Col, Row, Spinner } from "react-bootstrap";
import MainContext from "../../Configs/Context/MainContext";

const ForexCounts = () => {
  const context = useContext(MainContext);
  // const [loading, setLoading] = useState(true);

  const count = [
    {
      item: "Card1",
      count: context?.userFxData?.accountBalance,
      name: "Paam Balance ",
    },
    {
      item: "Card2",
      count: 0,
      name: "Equity",
    },
    {
      item: "Card3",
      count: context?.assetCount?.coinMargin?.total,
      name: "Free Margin",
    },
  ]

  return (
    <>
      <div>
        {/* {loading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : ( */}
        <Row className="g-4 mb-4">
          {count?.length > 0 && count.map((values) => {
            return (
              <>
                <Col sm={6} md={4}>
                  <div className="rewardDiv teamDiv">
                    <p className="mb-3">{values.name}</p>
                    <h3>{values?.count ? values?.count?.toFixed(4) : "00"}</h3>
                  </div>
                </Col>
              </>
            );
          })}
        </Row>
        {/* )} */}
      </div>
    </>
  );
};

export default ForexCounts;
