import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import MainContext from "../../Configs/Context/MainContext";
import ApiConfig from "../../Configs/ApiConfig";
import { PiCopySimpleLight } from "react-icons/pi";
import axios from "axios";

const Deposit = () => {
  const [importedFile, setImportedFile] = useState("");
  const token = localStorage.getItem("token");
  const user = useContext(MainContext);
  const [address, setAddress] = useState("");
  const [usdtAddress, setUsdtAddress] = useState(
    "0xc6C1aB9A3CC20087aD62fC860e177482a93b5D08"
  );
  const [upiCode, setUpicode] = useState("ivtmarket@Axis");
  const exchangeBalance = user?.exchangeBalance?.allExchangeTotal;

  const getDeposit = async (values) => {
    const res = await Axios({
      method: "GET",
      url: ApiConfig.deposit,
      headers: { token: token },
    });
    if (res.data.statusCode === 200) {
      setAddress(res.data.result.depositAddress);
    }
  };

  useEffect(() => {
    if (token) {
      getDeposit();
    }
  }, [token]);

  const handleCopy = () => {
    toast.success("Copied Successfully");
  };

  const getImportedFile = (e) => {
    const file = e.target.files[0];
    setImportedFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!importedFile) {
      alert("Please upload a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", importedFile);

    try {
      const response = await axios.post(ApiConfig.assetsTransaction, formData, {
        headers: {
          token: `${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response.data);
      alert("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file");
    }
  };

  return (
    <>
      <div>
        {/* <Row className="depositeDiv align-items-center mb-4">
          <Col md={8} xl={7} xxl={6} className="mb-4 mb-md-0">
            <h6 className="mb-4">
              Chain :{" "}
              <span
                className="text-white py-1 px-2"
                style={{ background: "#42B57C", borderRadius: "10px" }}
              >
                BEP20
              </span>
            </h6>
            <div className="mb-3">
              <h6>USDT Deposit Address</h6>
              <div className="depositesAddress">
                {address || "-"}{" "}
                <span>
                  <CopyToClipboard text={address} onCopy={() => handleCopy()}>
                    <PiCopySimpleLight />
                  </CopyToClipboard>
                </span>
              </div>
            </div>
            <h6>
              Current Balance: ${" "}
            </h6>
              {exchangeBalance ? exchangeBalance.toFixed(4) : 0.0}
            {exchangeBalance < 20 && (
              <p style={{ fontSize: "13px", color: "red" }}>
                You need at least 20 USDT to Proceed.
              </p>
            )}
          </Col>
          <Col md={4} xl={4} xxl={4}>
            <div className="depositeQr">
              <QRCode value={address} />
            </div>
          </Col>
        </Row> */}

        <Row className="depositeDiv mb-4" style={{ gap: "50px 0" }}>
          <Col xl={12} xxl={6} className="depositBorderFixer">
            <Row className="g-3">
              <Col lg={12}>
                <h2 className="fs-5 fw-bold m-0">Deposit For IVT Account</h2>
              </Col>
              <Col lg={12}>
                <span
                  className="d-inline-block text-white text-center py-1 px-2"
                  style={{
                    background: "#42B57C",
                    minWidth: "70px",
                    borderRadius: "10px",
                    fontSize: "14px",
                  }}
                >
                  USDT
                </span>
              </Col>
              <Col lg={12}>
                <div
                  className="d-flex align-items-center justify-content-between gap-4 mb-1"
                  style={{ fontSize: "14px" }}
                >
                  <span>USDT Deposit Address</span>
                  <span>
                    Current Balance : $
                    {user?.assetCount?.fuelAccountBalance
                      ? user?.assetCount.fuelAccountBalance?.total?.toFixed(4)
                      : "00"}
                  </span>
                </div>
                <div className="depositesAddress w-100">
                  {address || "-"}{" "}
                  <span>
                    <CopyToClipboard text={address} onCopy={() => handleCopy()}>
                      <PiCopySimpleLight />
                    </CopyToClipboard>
                  </span>
                </div>
              </Col>
              <Col lg={12}>
                <Row className="gy-4">
                  <Col sm={6} className="mx-auto text-center">
                    <p className="mb-3">
                      Chain :{" "}
                      <span
                        className="d-inline-block text-white text-center py-1 px-2"
                        style={{
                          background: "#42B57C",
                          minWidth: "70px",
                          borderRadius: "10px",
                          fontSize: "14px",
                        }}
                      >
                        BEP20
                      </span>
                    </p>
                    <div className="depositeQr mx-auto">
                      <QRCode value={address} />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={12}>
                <div>
                  <h5>Precautions:</h5>
                  <ol
                    className="ps-3 mb-0"
                    style={{ color: "#838383", fontSize: "14px" }}
                  >
                    <li>
                      Please do not deposit any non-USDT assets to the address
                      above.
                    </li>
                    <li>
                      Ensure you use BEP20 to deposit, or assets might be lost.
                    </li>
                    <li>The deposit may take a short while to arrive.</li>
                  </ol>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xl={12} xxl={6}>
            <Row
              className="g-3 depositeDiv-horizontalBorder"
            >
              <Col lg={12}>
                <h2 className="fs-5 fw-bold m-0">
                  Deposit For education Fees (Manually)
                </h2>
              </Col>
              <Col lg={12}>
                <span
                  className="d-inline-block text-white text-center py-1 px-2"
                  style={{
                    background: "#42B57C",
                    minWidth: "70px",
                    borderRadius: "10px",
                    fontSize: "14px",
                  }}
                >
                  UPI
                </span>
              </Col>
              <Col lg={12}>
                <div
                  className="d-flex align-items-center justify-content-between gap-4 mb-1"
                  style={{ fontSize: "14px" }}
                >
                  <span>UPI Address</span>
                  <span>
                    Current Balance : $
                    {user?.assetCount?.fuelAccountBalance
                      ? user?.assetCount.fuelAccountBalance?.total?.toFixed(4)
                      : "00"}
                  </span>
                </div>
                <div className="depositesAddress w-100">
                  {"ivtmarket@Axis" || "-"}{" "}
                  <span>
                    <CopyToClipboard text={address} onCopy={() => handleCopy()}>
                      <PiCopySimpleLight />
                    </CopyToClipboard>
                  </span>
                </div>
              </Col>
              <Col lg={12}>
                <Row className="gy-4">
                  <Col sm={6} className="text-center">
                    <p className="mb-3">UPI Code</p>
                    <div className="depositeQr mx-auto">
                      <QRCode value={upiCode} />
                    </div>
                  </Col>
                  <Col sm={6} className="text-center">
                    <p className="mb-3">
                      Chain :{" "}
                      <span
                        className="d-inline-block text-white text-center py-1 px-2 mx-1"
                        style={{
                          background: "#42B57C",
                          minWidth: "70px",
                          borderRadius: "10px",
                          fontSize: "14px",
                        }}
                      >
                        BEP20
                      </span>
                      <span style={{ color: "#42B57C" }}>USDT</span>
                    </p>
                    <div className="depositeQr mx-auto">
                      <QRCode value={usdtAddress} />
                    </div>
                    <div
                      className="depositesAddress mt-2"
                      style={{
                        border: "0",
                        width: "100%",
                        wordWrap: "break-word",
                      }}
                    >
                      {usdtAddress}
                      <span>
                        <CopyToClipboard
                          text={usdtAddress}
                          onCopy={() => handleCopy()}
                        >
                          <PiCopySimpleLight />
                        </CopyToClipboard>
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={12}>
                <div className="uploadTransactionReceipt">
                  {importedFile && (
                    <img
                      src={URL.createObjectURL(importedFile)}
                      alt=""
                      style={{
                        height: "25px",
                        width: "25px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                  <span
                    className="fileLabel"
                    style={{ left: importedFile ? "45px" : "8px" }}
                  >
                    {importedFile
                      ? importedFile.name
                      : "Upload transaction receipt"}
                  </span>
                  <input type="file" onChange={getImportedFile} />
                  <span className="fileBtn">
                    <img
                      src="/images/icons/guidance_left-arrow.png"
                      alt=""
                      className="img-fluid"
                    />
                  </span>
                </div>
              </Col>
              {importedFile && (
                <Col lg={12} className="text-end">
                  <Button onClick={handleSubmit} className="themeBtn">
                    Submit
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Deposit;
