import React, { useContext } from "react";
import { Button, Dropdown } from "react-bootstrap";
import MainContext from "../Configs/Context/MainContext";
import { FaBars } from "react-icons/fa";
import CustomModal from "./CustomModal/CustomModal";
import PurchasePlanModal from "./PurchasePlanModal";
import { UserLevel } from "../utils/helper";

const Header = ({ setSidebarToggle }) => {
  const context = useContext(MainContext);
  const fuelBalance = context?.assetCount?.fuelAccountBalance?.total;
  const currentPlan = context?.profile?.currentActivePlan;

  const { exchangeBalance, profile } = context || {};
  const { paamBalance } = exchangeBalance || {};
  const { activeDirectReferral } = profile || {};

  const referralLevel =
    activeDirectReferral >= UserLevel.length
      ? "12"
      : UserLevel[activeDirectReferral] || 0;

  const result = paamBalance > 1500 ? referralLevel : 0;

  const handleSidebar = () => {
    // context.setSidebar(true);
    setSidebarToggle(true);
  };

  let status;

  if (context?.userFxData?.status === "active") {
    status = "Approved";
  } else if (context?.userFxData?.status === "PENDING") {
    status = "Under Review";
  } else if (context?.userFxData?.status === "inactive") {
    status = "Rejected";
  } else {
    status = "";
  }

  return (
    <>
      <div className="d-flex flex-wrap align-items-center w-100">
        <div className="d-flex flex-wrap align-items-center">
          <Button className="slideBtn me-3" onClick={handleSidebar}>
            <FaBars />
          </Button>
        </div>
        <div className="d-flex flex-wrap align-items-center justify-content-around ms-auto gap-3 gap-lg-4">
          <p>
            <span>Current Plan :</span>{" "}
            {currentPlan?.validity
              ? `${currentPlan?.validity} ${currentPlan?.validityType}`
              : "-"}
          </p>
          <p style={{ color: fuelBalance < 20 ? "red" : "" }}>
            <span>System Balance:</span> ${" "}
            {fuelBalance ? fuelBalance?.toFixed(4) : "0.00"}
          </p>
          <p
            className="text-capitalize"
            style={{
              color:
                status === "Under Review"
                  ? "#FFA800"
                  : status === "Approved"
                  ? "#00C64F"
                  : status === "Rejected"
                  ? "red"
                  : "",
            }}
          >
            <span>Status:</span>{" "}
            {status ? status?.toLowerCase() : "Not Connected"}
          </p>
          <p style={{ color: "#42B57C" }}>
            <span>My Level: </span>
            {result}
          </p>

          <div className="d-flex align-items-center">
            <Dropdown className="dashUserDropdown">
              <Dropdown.Toggle
                variant="normal"
                id="dropdown-basic"
                className="dashUserName py-0"
              >
                {context?.profile?.email}
              </Dropdown.Toggle>

              {/* <Dropdown.Menu className='p-0'>
                <Dropdown.Item>
                  <Link to={ROUTES.DASHBOARD} className='dropdown-item py-2 px-3'><span><i className='las la-user-circle'></i></span>Profile</Link>
                </Dropdown.Item>
              </Dropdown.Menu> */}
            </Dropdown>
            <div className="dashUserIcon">
              <img
                src={
                  context?.profile?.profilePic
                    ? context?.profile?.profilePic
                    : "/images/profile.png"
                }
                alt="Admin"
                crossOrigin="anonymous"
              />
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        display={context?.purchaseOpen}
        handleClose={() => context?.setPurchaseOpen(false)}
        size="md"
        className="alertModal"
        centered
      >
        <PurchasePlanModal
          handleClose={() => context?.setPurchaseOpen(false)}
        />
      </CustomModal>
    </>
  );
};

export default Header;
